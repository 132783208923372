
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ConfigQuotaTicketDetail } from "@/domain/entities/ConfigQuotaTicket";
import { ConfigQuotaTicketController } from "@/app/ui/controllers/ConfigQuotaTicketController";
import { UserController } from "@/app/ui/controllers/UserController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import {
  ErrorMessageEntities,
  ModalMessageEntities,
  OptionsClass
} from "@/domain/entities/MainApp";
import { FlagsPermissionConfigQuotaTicket } from "@/feature-flags/flags-permission-config-quota-ticket";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { RequestListUser } from "@/data/payload/api/UserApiRequest";
import { RoleEntities, UserData } from "@/domain/entities/User";
import statusListData from "@/app/infrastructures/misc/common-library/StatusListData";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { SubData } from "@/domain/entities/MainApp";
import {
  ConfigQuotaTicketEditAccountApiRequest,
  ConfigQuotaTicketEditConfigApiRequest
} from "@/data/payload/api/ConfigQuotaTicketApiRequest";
import { QuotaTicketAccount } from "@/domain/entities/User";
import debounce from "lodash/debounce";
import { PaginationV2 } from "@/domain/entities/Pagination";

@Options({
  beforeRouteLeave(to: any, _: any, next: any) {
    if (this.isEdit) {
      if (MainAppController.messageModal || to.name === "login") {
        MainAppController.closeMessageModal();
        next();
        this.fetchDetail();
        this.reset();
      } else {
        this.leavePageConfirmation = true;
        this.nextPath = to.path;
        next(false);
      }
    } else {
      next();
      this.fetchDetail();
    }
  }
})
export default class Form extends Vue {
  leavePageConfirmation = false;
  nextPath = "";
  onLeavePage() {
    MainAppController.setOpenMessageModal(true);
    this.$router.push(this.nextPath);
  }

  mounted() {
    this.fetchDetail();
  }

  reset() {
    this.userRequest = {
      pagination: new PaginationV2(),
      search: "",
      status: new OptionsClass(),
      role: new OptionsClass()
    };
  }

  get id(): string {
    return String(this.$route.params?.id);
  }
  get isEdit(): boolean {
    return !!new RegExp(/edit/).exec(this.$route.path);
  }

  detailEntities = new ResponsePayloadV2();
  get detailData(): ConfigQuotaTicketDetail {
    return this.detailEntities.data;
  }
  async fetchDetail(): Promise<void> {
    try {
      MainAppController.closeMessageModal();
      MainAppController.closeErrorMessage();
      this.detailEntities.loading = true;
      this.detailEntities = await ConfigQuotaTicketController.getDetail(
        this.id
      );
      await this.fetchRoleList();
      await this.fetchUserList();
    } catch (error) {
      const errorEntities: ErrorMessageEntities = parsingErrorResponse(error);
      this.detailEntities.message = errorEntities.type;
    } finally {
      this.detailEntities.loading = false;
    }
  }

  async fetchRoleList(): Promise<void> {
    UserController.getRole({ account_type: "customer-service" });
  }
  get roleData(): OptionsClass[] {
    return UserController.roleData.map(
      (item: RoleEntities) =>
        new OptionsClass({
          name: item.name,
          value: String(item.id)
        })
    );
  }

  userRequest = {
    pagination: new PaginationV2(),
    search: "",
    status: new OptionsClass(),
    role: new OptionsClass()
  };
  async fetchUserList(): Promise<void> {
    try {
      UserController.setLoadingList(true);
      const res = await UserController.getUserList(
        new RequestListUser({
          version: "2",
          page: this.userRequest.pagination.page,
          limit: 20,
          isTotalData: true,
          account_type: "customer-service",
          search: this.userRequest.search,
          status: this.userRequest.status.value,
          roleId: this.userRequest.role.value
        })
      );
      this.userRequest.pagination = new PaginationV2({
        page: res.pagination.page,
        totalData: res.pagination.totalData
      });
      const data = res.data.map((item: UserData) => ({
        ...item,
        isError: false
      }));
      this.detailData.userList = data;

      this.indexEditAccount = null;
      this.disabledEditAccount = false;
    } finally {
      UserController.setLoadingList(false);
    }
  }
  get userData() {
    return {
      loading: UserController.isLoading,
      isError: UserController.isError,
      message: UserController.errorCause
    };
  }

  get columns(): any[] {
    return [
      {
        name: "Akun ID",
        styleHead: "w-32 text-left",
        render: (item: UserData) =>
          `<span class='text-left text-black-lp-300 flex break-words'>${item.account_id}</span>`
      },
      {
        name: "Username",
        styleHead: "w-40 text-left",
        render: (item: UserData) =>
          `<span class='text-left text-black-lp-300 flex break-words'>${item.username}</span>`
      },
      {
        name: "Role",
        styleHead: "w-40 text-left",
        render: (item: UserData) =>
          `<span class='text-left text-black-lp-300 flex break-words'>${item.account_role}</span>`
      },
      {
        name: "Total Kuota Tiket",
        key: this.isEdit ? "input-cell" : "",
        styleHead: "w-40 text-left",
        render: (item: UserData, index: number) =>
          this.isEdit
            ? {
                modelValue: item.dexBucketTicketAccount.dbtaBucketTicket,
                placeholder: "0",
                type: "number",
                min: "0",
                max: "9999999999",
                error: this.detailData.userList[index].isError,
                errorIcon: false,
                isErrorIconCaption: false,
                onChange: (value: string) =>
                  this.onChangeEditAccount({ value, item, index }),
                "onUpdate:isError": (val: boolean) =>
                  (this.detailData.userList[index].isError = val),
                disabled:
                  this.indexEditAccount &&
                  Number(this.indexEditAccount) !== index
              }
            : `<span class='text-left text-black-lp-300 flex break-words'>${item.dexBucketTicketAccount.dbtaBucketTicket}</span>`
      },
      {
        name: "Email",
        styleHead: "w-48 text-left",
        render: (item: UserData) =>
          `<span class='text-left text-black-lp-300 flex break-words'>${item.account_email}</span>`
      },
      {
        name: "No. Hp",
        styleHead: "w-40 text-left",
        render: (item: UserData) =>
          `<span class='text-left text-black-lp-300 flex break-words'>${item.account_phone_number}</span>`
      },
      {
        name: "Status Akun",
        styleHead: "w-40 text-left text-black-lp-300 break-words",
        render: (item: UserData) => ChipsStyles({ status: item.status })
      }
    ];
  }

  onSearch(value: string) {
    this.userRequest.search = value;
    this.fetchUserList();
  }

  get statusData(): OptionsClass[] {
    return statusListData(["active", "inactive"]);
  }
  onFilter(): void {
    this.userRequest.pagination.page = 1;
    this.fetchUserList();
  }

  get isEmpty(): boolean {
    return (
      !this.detailData?.userList?.length &&
      !this.userRequest.search &&
      !this.userRequest.status.value &&
      !this.userRequest.role.value
    );
  }

  onClickToggleStatus(value: boolean) {
    this.detailData.dbtcStatus = value ? "active" : "inactive";
  }

  get subData(): SubData {
    return new SubData({
      status: this.detailData.dbtcStatus,
      isEditable: !this.isEdit && this.isEditable,
      createdAt: this.detailData.dbtcCreatedAt,
      createdBy: this.detailData.dbtcCreatedBy,
      updatedAt: this.detailData.dbtcUpdatedAt,
      updatedBy: this.detailData.dbtcUpdatedBy,
      goEdit: this.goEdit
    });
  }

  goBack(): void {
    if (!this.isEdit) {
      this.$router.push("/customer-service/config-quota-ticket/");
    } else {
      this.$router.push(`/customer-service/config-quota-ticket/${this.id}`);
    }
  }
  goEdit(): void {
    this.$router.push(`/customer-service/config-quota-ticket/${this.id}/edit`);
    this.reset();
  }

  get isEditable(): boolean {
    return FlagsPermissionConfigQuotaTicket.permission_config_quota_ticket_enable.isEnabled();
  }

  get isDisableSave(): boolean {
    return !this.detailData.dbtcDescription;
  }
  async onSave() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      await ConfigQuotaTicketController.editConfig(
        new ConfigQuotaTicketEditConfigApiRequest({
          payload: new ConfigQuotaTicketDetail({
            dbtcId: this.detailData.dbtcId,
            dbtcName: this.detailData.dbtcName,
            dbtcDescription: this.detailData.dbtcDescription,
            dbtcStatus: this.detailData.dbtcStatus,
            dbtcCreatedBy: undefined,
            dbtcUpdatedBy: undefined
          })
        })
      );
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          image: "badge-success",
          title: "Perubahan Konfigurasi Kuota Tiket Berhasil!",
          textSuccess: "OK",
          onSubmit: this.goBack,
          imageWidth: "32",
          imageHeight: "32"
        })
      );
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          "Gagal Mengubah Konfigurasi Kuota Tiket!",
          this.onSave
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  disabledEditAccount = false;
  indexEditAccount: string | null = null;
  onChangeEditAccount(params: {
    value: string;
    item: UserData;
    index: number;
  }) {
    this.detailData.userList[
      params.index
    ].dexBucketTicketAccount.dbtaBucketTicket = params.value;
    this.indexEditAccount = String(params.index);
    this.disabledEditAccount = true;
    this.onEditAccount(params.item);
  }
  onEditAccount = debounce(async (item: UserData) => {
    if (item.dexBucketTicketAccount.dbtaBucketTicket) {
      MainAppController.closeMessageModal();
      MainAppController.closeErrorMessage();
      MainAppController.showLoading();
      try {
        await ConfigQuotaTicketController.editAccount(
          new ConfigQuotaTicketEditAccountApiRequest({
            accountId: item.account_id,
            payload: new QuotaTicketAccount({
              dbtaBucketTicket: Number(
                item.dexBucketTicketAccount.dbtaBucketTicket
              )
            })
          })
        );
        MainAppController.setSnackbarMessage(
          "Perubahan total Kuota Tiket Berhasil"
        );
        MainAppController.setSnackbarTimeout(3000);
        MainAppController.setSnackbar(true);

        this.indexEditAccount = null;
        this.disabledEditAccount = false;
      } catch (error) {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Gagal Mengubah total Kuota Tiket!",
            this.onSave
          )
        );
      } finally {
        MainAppController.closeLoading();
      }
    } else {
      this.disabledEditAccount = false;
    }
  }, 3000);
}
